import React, {useState, useEffect} from "react";
import CalendarForm from "./CalendarForm";
import {getCalendar} from "../requests";
import {observer} from "mobx-react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import {Calendar, momentLocalizer} from "react-big-calendar";

const localizer = momentLocalizer(moment);

function HomePage({calendarStore}) {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [calendarEvent, setCalendarEvent] = useState({});
    const [initialized, setInitialized] = useState(false);

    const hideModals = () => {
        setShowAddModal(false);
        setShowEditModal(false);
    };
    
    const getCalendarEvents = async () => {
        const response = await getCalendar();
        const events = response.data.map(eventObject => {
            return {
                ...eventObject,
                start: new Date(eventObject.start),
                end: new Date(eventObject.end)
            };
        });
        calendarStore.setCalendarEvents(events);
        setInitialized(true);
    };

    // Handle methods
    const handleSelect = (event, e) => {
        const {start, end} = event;
        const data = {
            title: "",
            start,
            end,
            allDay: false
        };
        setShowAddModal(true);
        setShowEditModal(false);
        setCalendarEvent(data);
    };

    const handleSelectEvent = (event, e) => {
        setShowAddModal(false);
        setShowEditModal(true);
        let {id, title, start, end, allDay} = event;
        start = new Date(start);
        end = new Date(end);
        const data = {id, title, start, end, allDay};
        setCalendarEvent(data);
    };

    useEffect(() => {
        if (!initialized) {
            getCalendarEvents();
        }
    });

    return (
        <div className="page">
            <Modal show={showAddModal} onHide={hideModals}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Calendar Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CalendarForm 
                        calendarStore={calendarStore}
                        calendarEvent={calendarEvent}
                        onCancel={hideModals.bind(this)}
                        edit={false}
                    />
                </Modal.Body>
            </Modal>
            
            <Modal show={showEditModal} onHide={hideModals}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CalendarForm  
                        calendarStore={calendarStore}
                        calendarEvent={calendarEvent}
                        onCancel={hideModals.bind(this)}
                        edit={true}
                    />
                </Modal.Body>
            </Modal>
            
            <Calendar 
                localizer={localizer}
                events={calendarStore.calendarEvents}
                startAccessor="start"
                endAccessor="end"
                selectable={true}
                style={{height: "70vh"}}
                onSelectSlot={handleSelect}
                onSelectEvent={handleSelectEvent}
            />
        </div>
    );
}

export default observer(HomePage);