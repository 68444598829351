const APIURL = "http://localhost:3000";
const axios = require("axios");
export const getCalendar = () => {
    return axios.get(`${APIURL}/calendar`);
}
export const addCalendar = data => {
    return axios.post(`${APIURL}/calendar`, data);
}
export const editCalendar = data => {
    return axios.put(`${APIURL}/calendar/${data.id}`, data);
}
export const deleteCalendar = id => {
    return axios.delete(`${APIURL}/calendar/${id}`);
}
