import {observable, action, decorate} from "mobx";

class CalendarStore {
    calendarEvents = [];
    setCalendarEvents(newEvents) {
        this.calendarEvents = newEvents;
    }
}

CalendarStore = decorate(CalendarStore, {
    calendarEvents: observable,
    setCalendarEvents: action
});

export {CalendarStore};